<template>
  <div class="edit-organization-memberships position-relative">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <h2>{{__('organizationMemberships')}}</h2>
    <template v-if="playerId !== null">
      <table class="editable-table">
        <tr v-for="(organizationMembership, index) in organizationMemberships" :key="'organization_appearance_'+index">
          <td v-if="organizationMembership.organization">
            <img v-if="organizationMembership.organization.logo && organizationMembership.organization.logo.substr(0,4).toLocaleLowerCase() === 'http'"
                 :src="organizationMembership.organization.logo" alt="-"
            />
            <div v-else v-html="organizationMembership.organization.logo"></div>
          </td>
          <td v-if="organizationMembership.organization">{{ organizationMembership.organization.name }}</td>
          <td>
            <single-select
                :items="membershipTypes"
                v-model="organizationMembership.type"
                :hide-details="true"
                :prepend-icon="getPrependIcon(organizationMembership)"
                @input="registerChange(organizationMembership)"
            />
          </td>
          <td>
            <text-field
                v-model="organizationMembership.joinedAt"
                :label="__('joinedAt')"
                :hide-details="true"
                :prepend-icon="getPrependIcon(organizationMembership)"
                @input="registerChange(organizationMembership)"
            />
          </td>
          <td class="editable-table__button" @click="toggleDeleted(organizationMembership)">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
          </td>
        </tr>
      </table>
      <div class="layout">
        <div class="flex xs6 mt">
          <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()"
               @click="saveOrganizationMemberships"
               v-slashes>{{ __('common.save_changes') }}
          </div>
        </div>
        <div class="flex xs6 mt">
          <div class="btn btn--info" :loadding="loading" @click="showOrganizationBrowser" v-slashes>
            {{__('addMembership')}}
          </div>
        </div>
      </div>
    </template>
    <p v-else>save first</p>
    <universal-browser
        ref="organizationBrowser"
        :endpoint="'/ingame/civilization/organization'"
        :field-title="'name'"
        :field-icon="'logo'"
        :title="__('organizationMemberships')"
        @itemSelected="addOrganizationMembership"
    />
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      loading: false,
      organizationMemberships: [],
      inProgress: 0,
      membershipTypes: [
        { value: 0, title: 'Member' },
        { value: 1, title: 'Legate' },
        { value: 2, title: 'Superlegate' },
      ]
    }),
    props: ['playerId'],
    watch: {
      playerId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      hasChanges() {
        return this.organizationMemberships.filter((organizationMembership) => {
          return this.isNew(organizationMembership) || this.isChanged(organizationMembership) || this.isDeleted(organizationMembership);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.organizationMemberships = [];
        if (this.playerId === null) {
          this.loading = false;
          return;
        }
        const params = {
          playerIds: this.playerId,
          pageSize: -1
        }
        apiSecured.get('/ingame/civilization/organizationmembership/', { params }).then(async (res) => {
          this.organizationMemberships = res.data;
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const organizationIds = this.organizationMemberships.filter(record => record.organizationId !== null).map(record => record.organizationId);
        if (organizationIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${organizationIds.join(',')})`, {
            params: {
              fields: 'id,name,logo'
            },
          }).then((res) => {
            this.organizationMemberships = this.organizationMemberships.map(record => {
              record.organization = res.data.find(resRecord => resRecord.id === record.organizationId);
              return record;
            });
            this.showInfoNotification('Organizations have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      registerChange(organizationMembership) {
        if (!this.isNew(organizationMembership)) {
          organizationMembership.changed = true;
          this.$forceUpdate();
        }
      },
      isNew(organizationMembership) {
        return organizationMembership.hasOwnProperty('new') && organizationMembership.new;
      },
      isChanged(organizationMembership) {
        return organizationMembership.hasOwnProperty('changed') && organizationMembership.changed;
      },
      isDeleted(organizationMembership) {
        return organizationMembership.hasOwnProperty('deleted') && organizationMembership.deleted;
      },
      showOrganizationBrowser() {
        this.$refs.organizationBrowser.openDialog();
      },
      addOrganizationMembership(organization) {
        if (!this.organizationMemberships.find((organizationMembership) => organizationMembership.organizationId === organization.id)) {
          const joinedAt = new Date();
          this.organizationMemberships.push(
            {
              playerId: this.playerId,
              organizationId: organization.id,
              organization: organization,
              type: this.membershipTypes[0].value,
              joinedAt: joinedAt.toISOString(),
              new: true
            }
          )
        }
      },
      toggleDeleted(organizationMembership) {
        if (this.isNew(organizationMembership)) {
          this.organizationMemberships = this.organizationMemberships.filter((fOrganizationMembership) => fOrganizationMembership !== organizationMembership);
          this.$forceUpdate();
        } else {
          organizationMembership.deleted = !this.isDeleted(organizationMembership);
          this.$forceUpdate();
        }
      },
      getPrependIcon(organizationMembership) {
        if (this.isDeleted(organizationMembership)) {
          return 'bin'
        } else if (this.isChanged(organizationMembership)) {
          return 'pencil'
        } else if (this.isNew(organizationMembership)) {
          return 'asterisk'
        }
        return null;
      },
      saveOrganizationMemberships() {
        this.organizationMemberships.forEach((organizationMembership) => {
          let endpoint = '/ingame/civilization/organizationmembership';
          let event = null;
          let method = null;
          let payload = null;
          if (this.isNew(organizationMembership)) {
            event = 'organizationMembershipCreated';
            method = apiSecured.post;
            payload = {
              playerId: this.playerId,
              organizationId: organizationMembership.organizationId,
              type: organizationMembership.type,
              joinedAt: organizationMembership.joinedAt,
            }
          } else if (this.isChanged(organizationMembership)) {
            endpoint = '/ingame/civilization/organizationmembership/' + organizationMembership.id;
            event = 'organizationMembershipUpdated';
            method = apiSecured.patch;
            payload = [
              {
                op: 'replace',
                path: `/type`,
                value: organizationMembership.type
              },
              {
                op: 'replace',
                path: `/joinedAt`,
                value: organizationMembership.joinedAt
              },
            ];
          } else if (this.isDeleted(organizationMembership)) {
            endpoint = '/ingame/civilization/organizationmembership/' + organizationMembership.id;
            event = 'organizationMembershipDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.loading = true;
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          });
        })
      }
    }
  }
</script>