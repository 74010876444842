<template>
  <div class="edit-player">
    <my-dialog :show="show" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.edit_player.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm4">
              <text-field
                  class="mt"
                  v-model="record.id"
                  :label="__('entity.common.id')"
                  :error-messages="errors.id"
                  maxlength="50"
                  :prepend-icon="isChanged('id') ? 'pencil' : null"
                  @input="registerChange('id')"
              />
              <text-field
                  v-model="record.name"
                  :label="__('entity.common.name')"
                  :error-messages="errors.name"
                  maxlength="50"
                  :prepend-icon="isChanged('name') ? 'pencil' : null"
                  @input="registerChange('name')"
              />
              <div class="flex xs12 mt">
                <text-area
                    v-model="record.about"
                    :label="__('entity.ingame.player.about')"
                    :error-messages="errors.about"
                    :prepend-icon="isChanged('about') ? 'pencil' : null"
                    @input="registerChange('about')"
                />
              </div>
              <select-reference
                  class="mt"
                  v-model="record.userId"
                  :endpoint="'/community/user'"
                  :field-title="'userName'"
                  :nothing-selected-message="__('common.no_user_selected')"
                  :prepend-icon="isChanged('userId') ? 'pencil' : ''"
                  @input="registerChange('userId')"
              />
              <text-field
                  v-model="record.joined"
                  :label="__('entity.ingame.player.joined')"
                  :error-messages="errors.joined"
                  maxlength="50"
                  :prepend-icon="isChanged('joined') ? 'pencil' : null"
                  @input="registerChange('joined')"
              />
              <text-field
                  v-model="record.nqUserId"
                  :label="'NQ User Id'"
                  :error-messages="errors.nqUserId"
                  :type="'number'"
                  :prepend-icon="isChanged('nqUserId') ? 'pencil' : null"
                  @input="registerChange('nqUserId')"
              />
              <text-field
                  v-model="record.founderStatus"
                  :label="__('entity.ingame.player.founder_status')"
                  :error-messages="errors.founderStatus"
                  maxlength="50"
                  :prepend-icon="isChanged('founderStatus') ? 'pencil' : null"
                  @input="registerChange('founderStatus')"
              />
              <text-field
                  v-model="record.location"
                  :label="__('entity.ingame.player.location')"
                  :error-messages="errors.location"
                  maxlength="50"
                  :prepend-icon="isChanged('location') ? 'pencil' : null"
                  @input="registerChange('location')"
              />
              <text-field
                  v-model="record.avatar"
                  label="Avatar"
                  :error-messages="errors.avatar"
                  maxlength="50"
                  :prepend-icon="isChanged('avatar') ? 'pencil' : null"
                  @input="registerChange('avatar')"
              />
              <text-field
                  v-model="record.facebook"
                  :label="__('entity.ingame.player.facebook')"
                  :error-messages="errors.facebook"
                  maxlength="50"
                  :prepend-icon="isChanged('facebook') ? 'pencil' : null"
                  @input="registerChange('facebook')"
              />
              <text-field
                  v-model="record.twitter"
                  :label="__('entity.ingame.player.twitter')"
                  :error-messages="errors.twitter"
                  maxlength="50"
                  :prepend-icon="isChanged('twitter') ? 'pencil' : null"
                  @input="registerChange('twitter')"
              />
              <div class="flex xs12 mt">
                <my-switch
                    v-model="record.nq"
                    :label="__('entity.ingame.player.nq')"
                    :append-icon="isChanged('nq') ? 'pencil' : null"
                    @input="registerChange('nq')"
                />
              </div>
              <div class="flex xs12">
                <div class="layout">
                  <div class="flex xs6 mt">
                    <div class="btn btn--success" :loading="loading"
                         :disabled="changedProperties.length === 0"
                         @click="update" v-slashes>{{ __('common.save_changes') }}
                    </div>
                  </div>
                  <div class="flex xs6 mt">
                    <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close')
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs8">
              <edit-organization-memberships ref="editOrganizationMemberships" :player-id="record.id"/>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";
  import EditOrganizationMemberships from "./EditPlayer/EditOrganizationMemberships";

  export default {
    components: { EditOrganizationMemberships },
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/civilization/player',
      emptyRecord: {
        id: null,
        name: '',
        userId: null,
        nq: false,
        about: '',
        joined: '',
        founderStatus: '',
        location: '',
        facebook: '',
        twitter: '',
        avatar: '',
        nqUserId: '',
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>

<style scoped>

</style>