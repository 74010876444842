<template>
  <div class="oreAppearanceReports view">
    <app-header :title="__('oreAppearanceReports')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="stellarObjectId"
            :endpoint="'/ingame/world/stellarObject'"
            :nothing-selected-message="__('filterByStellarObject')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="oreId"
            :endpoint="'/ingame/items/item'"
            :field-icon="'icon'"
            :field-title="'fullName'"
            :endpoint-params="{groupIds: '08d8a31f-50bb-4d8c-8187-a739b97274c8', recursiveGroupsFilter: true}"
            :nothing-selected-message="__('filterByOre')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-title="'userName'"
            :field-icon="'avatar'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('reportOreAppearance')" @click="showReportOreAppearance" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/report.svg"
               alt="report"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="oreAppearanceReports"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editOreAppearanceReport="editOreAppearanceReport"
          @sort="sort"
          @mergeOreAppearanceReport="mergeOreAppearanceReport"
          @declineOreAppearanceReport="declineOreAppearanceReport"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-ore-appearance-report ref="editOreAppearanceReport" @updated="refresh"
                                @created="sayThankYouForOreAppearanceReport"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditPlayer from '../../components/Ingame/EditPlayer';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import EditOreAppearanceReport from "../../components/Ingame/EditOreAppearanceReport";
  import { userMixin } from "../../mixins/user";

  const renderStatus = (value) => {
    switch (value) {
      case null:
        return '<span class="ribbon info">open</span>'

      case 'merged':
        return '<span class="ribbon success">merged</span>'

      case 'declined':
        return '<span class="ribbon error">declined</span>'

      default:
        return '<span class="ribbon warning">value</span>'
    }
  }

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin, userMixin],
    components: {
      EditOreAppearanceReport,
      AppHeader,
      DataTable,
      EditPlayer
    },
    data: () => ({
      loading: false,
      oreAppearanceReports: [],
      userId: null,
      oreId: null,
      stellarObjectId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      userId() {
        this.pagination.page = 1;
      },
      stellarObjectId() {
        this.pagination.page = 1;
      },
      oreId() {
        this.pagination.page = 1;
      },
    },
    methods: {
      sayThankYouForOreAppearanceReport() {
        this.refresh();
        this.$refs.editOreAppearanceReport.close();
        Swal.fire({
          title: this.__('thankYou'),
          html: this.__('sayThankYouForOreAppearanceReport').replace('$user', this.$store.state.user.username),
          type: 'success',
        });
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.userId !== null) {
          params.userIds = this.userId;
        }
        if (this.oreId !== null) {
          params.oreIds = this.oreId;
        }
        if (this.stellarObjectId !== null) {
          params.stellarObjectIds = this.stellarObjectId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/world/oreappearancereport', { params }).then(async (res) => {
          this.oreAppearanceReports = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.oreAppearanceReports_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const oreIds = [];
        const stellarObjectIds = [];
        const userIds = []
        let ores = [];
        let stellarObjects = [];
        let users = [];
        this.oreAppearanceReports.forEach(oreAppearanceReport => {
          if (!oreIds.includes(oreAppearanceReport.oreId)) {
            oreIds.push(oreAppearanceReport.oreId);
          }
          if (!stellarObjectIds.includes(oreAppearanceReport.stellarObjectId)) {
            stellarObjectIds.push(oreAppearanceReport.stellarObjectId);
          }
          if (!userIds.includes(oreAppearanceReport.userId)) {
            userIds.push(oreAppearanceReport.userId);
          }
        });
        const params = {
          fields: 'id,name,icon'
        };
        if (oreIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${[...new Set(oreIds)].join(',')})`, { params }).then((res) => {
            ores = res.data;
            this.showInfoNotification('Ores have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        if (stellarObjectIds.length > 0) {
          await apiSecured.get(`/ingame/world/stellarobject/(${[...new Set(stellarObjectIds)].join(',')})`, { params }).then((res) => {
            stellarObjects = res.data;
            this.showInfoNotification('Stellar objects have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        if (userIds.length > 0) {
          await apiSecured.get(`/community/user/(${[...new Set(userIds)].join(',')})`, {
            params: {
              fields: 'id,userName,avatar'
            }
          }).then((res) => {
            users = res.data;
            this.showInfoNotification('Users have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        this.oreAppearanceReports = this.oreAppearanceReports.map(oreAppearanceReport => {
          const ore = ores.find(ore => ore.id === oreAppearanceReport.oreId);
          const stellarObject = stellarObjects.find(stellarObject => stellarObject.id === oreAppearanceReport.stellarObjectId);
          const user = users.find(user => user.id === oreAppearanceReport.userId);

          oreAppearanceReport.oreIcon = ore.icon;
          oreAppearanceReport.oreName = ore.name;
          oreAppearanceReport.stellarObjectIcon = stellarObject.icon;
          oreAppearanceReport.stellarObjectName = stellarObject.name;
          oreAppearanceReport.userAvatar = user.avatar ? user.avatar : 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
          oreAppearanceReport.userName = user.userName;

          return oreAppearanceReport;
        })
      },
      showReportOreAppearance() {
        this.$refs.editOreAppearanceReport.showCreate();
      },
      editOreAppearanceReport(oreAppearanceReport) {
        this.$refs.editOreAppearanceReport.showEdit(oreAppearanceReport);
      },
      mergeOreAppearanceReport(report) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('oreAppearanceReportDoYouWantToMerge'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.just_du_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = `/ingame/world/oreappearancereport/${report.id}/merge`;
            apiSecured.patch(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('merged'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
      declineOreAppearanceReport(report) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('oreAppearanceReportDoYouWantToDecline'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.just_du_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = `/ingame/world/oreappearancereport/${report.id}/decline`;
            apiSecured.patch(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('declined'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      }
    },
    created() {
      this.setPageTitle(this.__('oreAppearanceReports'));
      if ('stellarObjectId' in this.$route.query) {
        const stellarObjectId = this.$route.query.stellarObjectId;
        if (stellarObjectId !== null && stellarObjectId !== undefined) {
          this.stellarObjectId = stellarObjectId;
        }
      }
      if ('userId' in this.$route.query) {
        const userId = this.$route.query.userId;
        if (userId !== null && userId !== undefined) {
          this.userId = userId;
        }
      }
      if ('oreId' in this.$route.query) {
        const oreId = this.$route.query.oreId;
        if (oreId !== null && oreId !== undefined) {
          this.oreId = oreId;
        }
      }
      this.dataTableFields = [
        { name: "stellarObjectIcon", title: '', type: "image", disableSorting: true },
        {
          name: "stellarObjectName",
          title: this.__('entity.common.stellar_object'),
          type: "default",
          disableSorting: true
        },
        { name: "oreIcon", title: '', type: "image", disableSorting: true },
        { name: "oreName", title: this.__('entity.common.ore'), type: "default", disableSorting: true },
        { name: "userAvatar", title: '', type: "image", disableSorting: true },
        { name: "userName", title: this.__('entity.common.user'), type: "default", disableSorting: true },
        { name: "status", title: 'Status', type: "custom", render: renderStatus },
        { name: "highestAltitudeFromSurface", title: 'highestAlt', type: "numeric" },
        { name: "lowestAltitudeFromSurface", title: 'lowestAlt', type: "numeric" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: this.__('editOreAppearanceReport'),
          icon: 'pencil',
          event: 'editOreAppearanceReport',
          vif: (record) => {
            return record.status === null && (this.hasOneRoles(['InGame Items Editor']) || record.userId === this.$store.state.user.id)
          }
        },
        {
          title: this.__('merge'), icon: 'check', event: 'mergeOreAppearanceReport',
          vif: (record) => {
            return record.status === null && this.hasOneRoles(['InGame Items Editor'])
          }
        },
        {
          title: this.__('decline'), icon: 'cross', event: 'declineOreAppearanceReport',
          vif: (record) => {
            return record.status === null && this.hasOneRoles(['InGame Items Editor'])
          }
        },
      ];
      this.refresh();
    }
  }
</script>
