<template>
  <div class="edit-ore-appearance-report">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" :max-width="'600px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('editOreAppearanceReport')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div v-html="__('reportOreAppearanceExplanation')"></div>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <label>{{ __('entity.common.stellar_object') }}</label>
              <select-reference
                  v-model="record.stellarObjectId"
                  :endpoint="'/ingame/world/stellarobject'"
                  :nothing-selected-message="__('common.no_stellar_object_selected')"
                  :prepend-icon="isChanged('stellarObjectId') ? 'pencil' : ''"
                  @input="registerChange('stellarObjectId')"
              />
            </div>
            <div class="flex xs12">
              <label>{{ __('entity.common.ore') }}</label>
              <select-reference
                  v-model="record.oreId"
                  :endpoint="'/ingame/items/item'"
                  :field-icon="'icon'"
                  :field-title="'fullName'"
                  :endpoint-params="{groupIds: '08d8a31f-50bb-4d8c-8187-a739b97274c8', recursiveGroupsFilter: true}"
                  :nothing-selected-message="__('common.no_ore_selected')"
                  :prepend-icon="isChanged('oreId') ? 'pencil' : ''"
                  @input="registerChange('oreId')"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="record.highestAltitudeFromSurface"
                  :label="__('entity.ingame.ore_appearance.highest_altitude_from_surface')"
                  :type="'number'"
                  :prepend-icon="isChanged('highestAltitudeFromSurface') ? 'pencil' : ''"
                  @input="registerChange('highestAltitudeFromSurface')"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="record.lowestAltitudeFromSurface"
                  :label="__('entity.ingame.ore_appearance.lowest_altitude_from_surface')"
                  :type="'number'"
                  :prepend-icon="isChanged('lowestAltitudeFromSurface') ? 'pencil' : ''"
                  @input="registerChange('lowestAltitudeFromSurface')"
              />
            </div>
            <div class="flex xs12 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                   @click="update" v-slashes>{{ __('common.save_changes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";

  export default {
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/world/oreappearancereport',
      emptyRecord: {
        id: null,
        oreId: null,
        stellarObjectId: null,
        highestAltitudeFromSurface: null,
        lowestAltitudeFromSurface: null,
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>